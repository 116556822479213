<template>
    <div>
      <v-card :loading="loading">
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        >
        <v-icon
          class="mx-2"
          color="white"
          :disabled="loading"
          @click="openTab"
        >
          {{icons.openInNew}}
        </v-icon>
        <span class="text -h6">{{name}}</span>
    
          <v-spacer></v-spacer>
        <v-btn 
          icon
          @click="close"
        > 
          <v-icon>{{icons.close}}</v-icon>
        </v-btn>
        </v-toolbar>
        <div  class="pa-2"> 
        <v-card-text class="blue-grey lighten-5  overflow-y-auto"   style="max-height: 1000px">
          <iframe v-if="!loading && !this.$vuetify.breakpoint.xs" :src="url" style="width:100%; height:650px" ></iframe>
        </v-card-text>
        </div>
      
      </v-card>
    
      </div>
      

</template> 

<script>
import { mdiClose, mdiOpenInNew } from '@mdi/js';


export default {
  name: 'DialogPDFView',
  props:['url','loading',"name"],
  data () {
    return {
    icons:{
      "close":mdiClose,
      "openInNew":mdiOpenInNew
    },
    }
  },

  watch: {
    loading: function(){
      if( !this.loading && this.$vuetify.breakpoint.xs)  window.open(this.url);
    }

  },


  methods: {

    close (){
      this.$emit('closeEmit')
    },
    //別タブで開く
    openTab(){
      window.open(this.url);
    }
 
  },
}

</script>
